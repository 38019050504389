import * as angular from 'angular';

"use strict";

angular.module('ModuleModule')
       .controller('ModuleController', ModuleController);

ModuleController.$inject = ['ModuleService', '$scope', '$state'];

// Controller Constructor
function ModuleController(ModuleService, $scope, $state) {
    // Creating the view model reference
    let vm = this;
    vm.data = '';
    vm.main = '';

    vm.$onInit = function () {
        try {
            $scope.data = vm.data;
            $scope.main = vm.main;

        } catch (e) {
            console.log(e);
        }

  }

  vm.showIconsBlock = (data) => {
      data.showIconBox = true;
      data.editingBlocIcon = true;
      data.editingModuleIcon = false;
  }

    vm.getLinkUrl = function (id, stationID) {
        return $state.href('edit-bloc', {id: id, stationId: stationID});
    };


    /**
     * This function checks whether a given string is a valid UUID (Universally Unique Identifier) format.
     * @param {string} uuid - The string to be checked.
     * @returns {boolean} - Returns true if the string is a valid UUID format, otherwise returns false.
     *
     */
    vm.isValidUUID = (uuid: string): boolean => {
        const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[8-9a-b][0-9a-f]{3}-[0-9a-f]{12}$/i
        return uuidRegex.test(uuid)
    }

    /**
     *
     * This function exports a module and retrieves a zip file,
     * and then initiates a download for the retrieved file.
     * @param {string} jobID - The ID of the job associated with the module to be exported.
     *
     */
    vm.exportModule = async (jobID) => {

        try {
            const response = await ModuleService.exportModule(jobID)
            let blob = response.data
            let zipBlob = new Blob([blob], {type: "application/zip"});
            let url = window.URL || window.webkitURL;
            vm.fileUrl = url.createObjectURL(zipBlob);
            let a = document.createElement("a");
            a.href = vm.fileUrl;
            a.download = `export-module-${jobID}-.zip`
            a.click();

        } catch (exception) {
            console.log(exception)
        }
    }
}

export default ModuleController;
