import * as angular from 'angular';
import * as $ from "jquery";

"use strict";

angular
    .module('FieldModule')
    .factory('FlowChartFactory', FlowChartFactory);


FlowChartFactory.$inject = ['fieldService', 'Configuration', '$http'];


/**
 * Object that will create a Plain Data Factory, all methods of factory
 * will be available from outside.
 */
function FlowChartFactory(fieldService, Configuration, $http) {
    let factory = this;

    factory.getBlocFlowMarkDown = (blocId) => {

        let apiBase = Configuration.getApiUrl() + 'generate-bloc-flow-markdown';
        let config = {
            params: {
                blocId : blocId
            }
        };

        return $http.get(apiBase, config);
    }
    return factory;
}

