import * as angular from 'angular';

declare const window: any;

"use strict";

angular.module('FieldModule')
    .controller('FlowChartController', FlowChartController);

// Injection of each class
FlowChartController.$inject = [ '$rootScope', '$scope', 'ScopeAndLocal', 'FlowChartFactory'];

// Controller Constructor
function FlowChartController($rootScope, $scope, ScopeAndLocal, FlowChartFactory) {

    let vm = this;

    vm.$onInit = async function () {
        try {
            ScopeAndLocal.startVariable(vm, $scope, 'action');
            ScopeAndLocal.startVariable(vm, $scope, 'scanStation');

        } catch (e) {
            console.log(e);
        }
    }

    /**
     * This function loads the flowchart of the fields in the bloc and displays in the pop up using
     * mermaid js https://mermaid-js.github.io/mermaid/#/
     * Due to sizing issues, until we review the library more we cannot use the pop up and instead we a generating a url and loading that as a pop up
     * TODO figure out why the graphs is stretching to 500,000px when rendered in Blocworx
     */
    vm.loadFlowChart = async () => {

        // will be getting the bloc and the markdown file
        let markdownResponse = await FlowChartFactory.getBlocFlowMarkDown(vm.scanStation.id);
        let markdown = markdownResponse.data.data;

        // replacing reserved words that a breaking things, merge js has no workaround
        let reserverdWords = {
            'blank':'bllank',
            'style':'sttyle',
            'default': 'deffault'
        };

        // This wil replace all reserved words with correct replacements
        angular.forEach(reserverdWords, function(replacement,reservedWord) {
           markdown = markdown.replaceAll(reservedWord,replacement);
        });

        // this will redirect to the main blocworx.com api
        let url = 'https://www.blocworx.com/flow-chart?markdown=' + markdown;

        // we are using the window object to open in a new window.
        window.open(url, '_blank').focus();

    }

}

export default FlowChartController;