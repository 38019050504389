import * as angular from 'angular';
import * as echarts from 'echarts/index.blank'
import EChartOption = echarts.EChartsOption;
import $ from "jquery";

"use strict";

angular.module('FieldModule')
    .service('eChartBasicPie', eChartBasicPie);


eChartBasicPie.$inject = [];

/**
 * Service constructor.
 * @constructor
 */
function eChartBasicPie() {

    let service = this;

    /**
     * method to build the basic line chart
     *
     * @param fieldSlug
     */
    service.build = async function (fieldSlug, eChartData, genericOptions) {

        let seriesArray = [];

        var pieChartData = []
        angular.forEach(eChartData.data.legendArray, function (legend) {
            pieChartData[legend] = {
                value: 0,
                name: legend
            };
        });
        angular.forEach(eChartData.data.itemCountResults, function (itemToCountRow, legendKey) {
            angular.forEach(itemToCountRow, function (countValue) {
                pieChartData[legendKey].value = pieChartData[legendKey].value + countValue;
            });
        });

        var pieChartDataNoKeys = [];
        angular.forEach(eChartData.data.legendArray, function(legend) {
            pieChartDataNoKeys.push(pieChartData[legend]);
        });

        // based on prepared DOM, initialize echarts instance
        let echartDataSeriesLayout = echarts.init(document.getElementById('echart-' + fieldSlug));
        let options = {
            tooltip: {
                trigger: 'item'
            },
            legend: {
                top: '5%',
                left: 'center'
            },
            series: [
                {
                    type: 'pie',
                    radius: '80%',
                    data: pieChartDataNoKeys,
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                }
            ]
        };

        //options = Object.assign(options, genericOptions);

        echartDataSeriesLayout.setOption(options);
    }
}

