import * as angular from 'angular';
import StandardComponent from "components/standards/components/StandardComponent";
import GridFieldController from "components/grid/components/grid-field/controllers/gridField.controller";

"use strict";

class GridField extends StandardComponent {

    // This will enable the debug mode of this class
    public debug = false;
    public moduleFolder = 'components/grid/components/grid-field';

    /**
     * PLease make sure that each function contain correct
     * documentation.
     */
    public bindings = {
        main : '=',
        data : '=',
        grid : '=',
        child : '=',
        key : '=',
        gridfield : '=',
        scanStationController: '=',
        scanStation: '=',
        fc: '='
    }

    // This will set what will be the controller of this component
    public controller : any = GridFieldController;

    // This will set an alias
    public controllerAs: string = 'gf';
}

angular.module('GridModule').component('gridField', <StandardComponent> new GridField);

