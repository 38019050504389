// Loading all modules
import './standards/module'
import './permission/module'
import './export/module'
import './sound/module'
import './blocworx/module'
import './configuration/module'
import './datetime/module'
import './moment/module'
import './prevent-template-cache/module'
import './exceptions/module'
import './validators/module'
import './field/module'
import './filter/module'
import './grid/module'
import './scanstation/module'
import './superuser/module'
import './calendar/module'
import './icon/module'
import './page/module'
import './sidebar-pages/module'
import './url/module'
import './page-bar/module'
import './breadcrumb/module'
import './back-button/module'
import './help-mode/module'
import './drag-drop/module'
import './template/module'
import './download/module'
import './roles-and-permissions/module'
import './module/module'
import './users/module'
import './reports/module'
import './languages/module'
import './detect-inactivity/module'
import './manage-inactivity/module'
import './reset-forgot-password/module'
import './management/module'
import './login-attempts/module'
import './advanced-reports/module'
import './buttons/module'
import './two-factor-authentication/module'
import './sign-in-with-google/module'
import './sockets/module'
import './schedulers-data/module'
