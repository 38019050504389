import * as angular from 'angular';

"use strict";

angular.module('FieldModule')
       .directive('dropdownFromStationField', dropdownFromStationFieldDirective)
       .directive('dropdownFromStationFieldPrint', dropdownFromStationFieldPrintDirective)
       .service('dropdownFromStationField', dropdownFromStationField)
    .directive('dropdownFromStationFieldBuild',dropdownFromStationFieldBuild);

dropdownFromStationFieldDirective.$inject = ['Configuration'];
/**
 * Directive object that will build the field.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function dropdownFromStationFieldDirective(Configuration) {
    let directive = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/dropdown-from-station/field.html?v=' + version + 'rand=' + random;

    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl
    });
}

dropdownFromStationFieldPrintDirective.$inject = ['Configuration'];
/**
 * Directive that will show the field result in the table.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function dropdownFromStationFieldPrintDirective(Configuration) {
    let directive = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/dropdown-from-station/print.html?v=' + version + 'rand=' + random;

    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl
    });
}

/**
 * Directive object that will build or edit the field.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function dropdownFromStationFieldBuild(Configuration) {
    let directive = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/dropdown-from-station/build-edit.html?v=' + version + 'rand=' + random;

    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl
    });
}

/**
 * Service constructor.
 * @constructor
 */
function dropdownFromStationField() {
    let service = this;

    /**
     * Returns the initial Parameters for this field, to be distinguished from other parameters the field may have in the future after creating it
     */
    service.getInitialParams = async function () {
        try
        {

            let initialParams = {
                'dropdownFromStationLookUp': {
                    'subParameters': {
                        'stationId': {
                            'validation' : {
                                'required': true,
                                'validationResponse': 'Please choose a bloc to look up.',
                                'requiredType': 'stringMustNotBeEmpty'
                            }
                        },
                        'fieldIdToLookUp': {
                            'validation' : {
                                'required': true,
                                'validationResponse': 'Select the remote field that will be searched against.',
                                'requiredType': 'stringMustNotBeEmpty'
                            }
                        }
                    },
                    'validation' : {
                        'required': true,
                        'validationResponse': 'Please select a bloc and a field to look up',
                        'requiredType': 'listMustHaveOne'
                    }
                },
                'dropdownFromStationOrder': {
                    'validation' : {
                        'required': false,
                    }
                },
                'dropdownFromStationReverseOrder': {
                    'validation' : {
                        'required': false,
                    }
                }
            };

            return initialParams;

        } catch (e) {
            console.log(e);
        }
    }
}
