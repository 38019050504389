import * as angular from 'angular';

"use strict";

angular
    .module('GridModule')
    .factory('GridFactory', GridFactory)
    .config(GridConfiguration);

/**
 * Object that will set aHrefSanitizationWhitelist for requests
 * in this factory.
 *
 * @param $compileProvider
 * @constructor
 */
function GridConfiguration($compileProvider) {
    $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|tel|file|blob):/);
}

GridFactory.$inject = ['$http', 'GridSemaphore', 'Configuration',
    'MissingScanStationFieldsExceptionFactory', 'ArgumentUndefinedExceptionFactory',
    'SuperuserService'
];

/**
 * Object that will create a Grid Factory, all methods of factory
 * will be available from outside.
 *
 * @param $http
 * @param GridSemaphore
 * @param Configuration
 * @param MissingScanStationFieldsExceptionFactory
 * @param ArgumentUndefinedExceptionFactory
 * @param SuperuserService
 * @constructor
 */
function GridFactory($http, GridSemaphore, Configuration, MissingScanStationFieldsExceptionFactory,
                     ArgumentUndefinedExceptionFactory, SuperuserService) {
    let factory = this;

    /**
     * This will return the url of the file to load
     * @returns {*}
     */
    factory.gridSemaphoreURL = function () {
        return GridSemaphore.gridSemaphoreURL();
    }

    /**
     * This will return the status in a text format
     * @returns {string}
     */
    factory.getGridStatusText = function () {
        return GridSemaphore.getGridStatusText();
    }

    /**
     * This will say if the grid is enabled
     * @returns {boolean}
     */
    factory.isGridEnabled = function () {
        return GridSemaphore.isGridEnabled();
    }

    /**
     * Function that will check if the current user is valid to show
     * elements.
     * @returns {boolean}
     */
    factory.isValidUser = function (main) {
        // Check if it is a super user
        return SuperuserService.isSuperUser(main);
    }

    /**
     * This will do the action of click of the semaphore
     * @returns {*}
     */
    factory.semaphoreClick = function () {
        return GridSemaphore.click();
    }

    /**
     * Method that will remove the count after check that exist
     * quantity == 1 o bigger than 1
     * @param child
     * @returns {*}
     */
    factory.removeQuantity = function (child) {
        delete child.count;
        return child;
    }

    /**
     * Method that will return the status in a text format
     * @returns {string}
     */
    factory.getGridButtonAction = function () {
        return GridSemaphore.getGridButtonAction();
    }

    /**
     * This is the main function to get the grid lines
     * @param scanStationId
     * @param scanStationFields
     * @returns {*}
     */
    factory.getGridLines = function (scanStationId, scanStationFields) {

        let apiBase = Configuration.getApiUrl() + 'get-scan-station-grid-fields';
        let data = {
            'scanStationId': scanStationId,
            'scanStationFields': scanStationFields
        };
        return $http.post(apiBase, data);
    };

    /**
     * This is the main function to get specific grid lines
     * @param scanStationId
     * @param scanStationFields
     * @param gridIdsToUpdate
     * @returns {*}
     */
    factory.getSpecificGridLines = function (scanStationId, gridIdsToUpdate) {

        let apiBase = Configuration.getApiUrl() + 'get-specific-scan-station-grid-fields';
        let data = {
            'scanStationId': scanStationId,
            'gridIdsToUpdate': gridIdsToUpdate
        };
        return $http.post(apiBase, data);
    };

    /**
     * This is the main function to add a new grid item
     * @param scanStationId
     * @param parentId
     * @param gridWidth
     * @returns {*}
     */
    factory.addGridItem = function (scanStationId, gridWidth, parentId) {

        let apiBase = Configuration.getApiUrl() + 'add-grid-item';
        let data = {
            'scanStationId': scanStationId,
            'gridWidth': gridWidth,
            'parentId': parentId
        };
        return $http.post(apiBase, data);
    };

    /**
     * This is delete a grid item, either a row or a child
     * @param gridId
     * @returns {*}
     */
    factory.deleteGridItem = function (gridId) {

        let apiBase = Configuration.getApiUrl() + 'delete-grid-item';
        let data = {
            params: {
                gridId: gridId
            }
        };
        return $http.delete(apiBase, data);
    };

    /**
     * This is delete a grid item, either a row or a child
     * @param elementIdToUpdateTo
     * @param fieldId
     * @returns {*}
     */
    factory.changeFieldParentElement = async function (elementIdToUpdateTo, fieldId,) {

        let apiBase = Configuration.getApiUrl() + 'change-field-parent-element';
        let data = {
            elementIdToUpdateTo: elementIdToUpdateTo,
            fieldId: fieldId
        };
        return $http.post(apiBase, data);
    };

    /**
     * This is delete a grid item, either a row or a child
     * @param gridId
     * @param newGridSize
     * @returns {*}
     */
    factory.editGridSize = async function (gridId, newGridSize,) {

        let apiBase = Configuration.getApiUrl() + 'edit-grid-size';
        let data = {
            gridId: gridId,
            newGridSize: newGridSize
        };
        return $http.post(apiBase, data);
    };


    /**
     * This is reorder a field
     * @param direction
     * @param fieldId
     * @returns {*}
     */
    factory.reOrderFields = function (direction, fieldId) {

        let apiBase = Configuration.getApiUrl() + 'reorder-fields';
        let data = {
            direction: direction,
            fieldId: fieldId
        };
        return $http.post(apiBase, data);
    };

    /**
     * Method that updates just the specific grids that have been affected by a field move
     * @param gridsToUpdate
     * @param lines
     * @returns {object}
     */
    factory.updateGridsAfterFieldMove = function (gridsToUpdate, lines) {
        angular.forEach(lines, function (line) {
            if (typeof line.children != 'undefined') {
                angular.forEach(line.children, function (childElement, childElementId) {
                    if (childElementId == gridsToUpdate.data.originElement.grid_id) {
                        line.children[childElementId] = gridsToUpdate.data.originElement;
                    }

                    if (childElementId == gridsToUpdate.data.destinationElement.grid_id) {
                        line.children[childElementId] = gridsToUpdate.data.destinationElement;
                    }
                });
            }
        });

        return lines;
    }

    /**
     * This is to reorder a row in a bloc
     * @param direction
     * @param gridId
     * @returns {*}
     */
    factory.reOrderGridRow = function (direction, gridId, stationId) {

        let apiBase = Configuration.getApiUrl() + 'reorder-grid-row';
        let data = {
            direction: direction,
            gridId: gridId,
            stationId: stationId
        };
        return $http.post(apiBase, data);
    };


    return factory;
}

