import * as angular from 'angular';

'use strict';

angular.module('app').config(legacyRoutes);

legacyRoutes.$inject = ['ConfigurationProvider', '$stateProvider'];

function legacyRoutes(ConfigurationProvider, $stateProvider) {

    // This will generate all the state provider requests
    $stateProvider
        .state('o-home', {
            parent: 'app',
            url: '/compliance',
            views: {
                'parent': {
                    templateUrl: 'modules/compliance/home.html?nd=' + Date.now(),
                    controller: 'MainController as main'
                }
            }
            , params: {}
        })
        .state('o-admin-modules', {
            parent: 'app',
            url: '/compliance/admin',
            views: {
                'parent': {
                    templateUrl: 'modules/compliance/admin-modules.html?nd=' + Date.now(),
                    controller: 'MainController as main'
                }, params: {
                    editEntity: true,
                    editMode: true
                }
            },
            params: {
                usesRole: 'active-modules',
                sidebarHighlighter: 'admin'
            }
        })
        .state('o-create-module', {
            parent: 'app',
            url: '/module/compliance/create-module',
            views: {
                'parent': {
                    templateUrl: 'modules/compliance/create-module.html?nd=' + Date.now(),
                    controller: 'MainController as main'
                }, params: {
                    editEntity: true,
                    editMode: true
                }
            },
            params: {
                usesRole: 'create-module',
                sidebarHighlighter: 'admin'
            }
        })
        .state('o-edit-module', {
            parent: 'app',
            url: '/module/compliance/edit-module/{moduleID:string}',
            views: {
                'parent': {
                    templateUrl: 'modules/compliance/edit-module.html?nd=' + Date.now(),
                    controller: 'MainController as main'
                }
            },
            params: {
                usesRole: 'module-setup'
            }
        })
        .state('o-module-files', {
            parent: 'app',
            url: '/module/compliance/{moduleID:string}/module-files',
            views: {
                'parent': {
                    templateUrl: 'modules/compliance/module-files.html?nd=' + Date.now(),
                    controller: 'MainController as main'
                }
            },
            params: {
                usesRole: 'no-role'
            }
        })
        .state('o-module-details', {
            parent: 'app',
            url: '/module/compliance/{moduleID:string}/module-details',
            views: {
                'parent': {
                    templateUrl: 'modules/compliance/module-details.html?nd=' + Date.now(),
                    controller: 'MainController as main'
                }
            },
            params: {
                usesRole: 'no-role'
            }
        })
        .state('o-manage-questions', {
            parent: 'app',
            url: '/module/compliance/{moduleID:string}/manage-questions',
            views: {
                'parent': {
                    templateUrl: 'modules/compliance/manage-questions.html?nd=' + Date.now(),
                    controller: 'MainController as main'
                }
            },
            params: {
                usesRole: 'no-role',
                sidebarHighlighter: 'admin'
            }
        })
        .state('o-edit-question', {
            parent: 'app',
            url: '/module/compliance/{moduleID:string}/question/{questionID:int}/edit',
            views: {
                'parent': {
                    templateUrl: 'modules/compliance/edit-question.html?nd=' + Date.now(),
                    controller: 'MainController as main'
                }, params: {
                    editEntity: true,
                    editMode: true
                }
            },
            params: {
                usesRole: 'scan-edit',
                sidebarHighlighter: 'admin'
            }
        })
        .state('o-module', {
            parent: 'app',
            url: '/module/compliance/{uniqueModuleSlug:string}/{currentQuestionSlug:string}',
            views: {
                'parent': {
                    templateUrl: 'modules/compliance/module.html?nd=' + Date.now(),
                    controller: 'MainController as main'
                }
            },
            params: {
                sidebarHighlighter: 'home',
                moduleID: null,
                usesRole: 'no-role',
            }
        })
        .state('o-complete-review', {
            parent: 'app',
            url: '/module/compliance/{uniqueModuleSlug:string}/summary/complete-review/final',
            views: {
                'parent': {
                    templateUrl: 'modules/compliance/complete-review.html?nd=' + Date.now(),
                    controller: 'MainController as main'
                }
            },
            params: {
                sidebarHighlighter: 'home',
                usesRole: 'no-role'
            }
        })
        .state('o-continual-assessment', {
            parent: 'app',
            url: '/module/compliance/continual-assessment',
            views: {
                'parent': {
                    templateUrl: 'modules/compliance/continual-assessment.html?nd=' + Date.now(),
                    controller: 'MainController as main'
                }
            },
            params: {
                sidebarHighlighter: 'o-continual-assessment',
                usesRole: 'no-role',
            }
        })


        .state('admin', {
            parent: 'app',
            url: '/admin',
            views: {
                'parent': {
                    templateUrl: 'views/admin/home-admin.html?nd=' + Date.now(),
                    controller: 'MainController as main'
                }
            },
            params: {
                usesRole: 'admin-landing-page',
                sidebarHighlighter: 'admin'
            }
        })
        .state('logout', {
            parent: 'app',
            url: '/logout?inactivitylogout',
            views: {
                'parent': {
                    templateUrl: 'views/logout.html?nd=' + Date.now(),
                    controller: 'AuthController as auth'
                }
            }
        })
        .state('auth', {
            parent: 'app',
            url: '/auth?inactivitylogout',
            views: {
                'parent': {
                    templateUrl: 'views/authView.html?nd=' + Date.now(),
                    controller: 'AuthController as auth'
                }
            }
        })
        .state('forgot-password', {
            parent: 'app',
            url: '/forgot-password',
            views: {
                'parent': {
                    templateUrl: 'components/reset-forgot-password/template/forgot-password-container.html?nd=' + Date.now(),
                    controller: 'MainController as main'
                }
            }
        })
        .state('reset-password', {
            parent: 'app',
            url: '/reset-password/{token:string}',
            views: {
                'parent': {
                    templateUrl: 'components/reset-forgot-password/template/reset-password-container.html?nd=' + Date.now(),
                    controller: 'MainController as main'
                }
            }
        })
        .state('public-form', {
            parent: 'app',
            url: '/public-form/{id:int}/{stationId:string}/{stationDataID:int}/{publicFormKey:string}/{publicFormValue:string}',
            views: {
                'parent': {
                    templateUrl: 'views/front-end/scan-station.html?nd=' + Date.now(),
                    controller: 'MainController as main'
                }
            }
        })
        .state('manage-users', {
            parent: 'app',
            url: '/admin/manage-users?fromBlocId&username&testingusername&name&profileFilter',
            views: {
                'parent': {
                    templateUrl: 'components/users/template/manage-users.html?nd=' + Date.now(),
                    controller: 'MainController as main'
                }
            }
            , params: {
                editEntity: false,
                editMode: false,
                usesRole: 'manage-users',
                sidebarHighlighter: 'admin'
            }
        })
        .state('file-manager', {
            parent: 'app',
            url: '/manage-data/file-manager',
            views: {
                'parent': {
                    templateUrl: 'views/manage-data/file-manager.html?nd=' + Date.now(),
                    controller: 'MainController as main'
                }
            }
            , params: {
                editEntity: false,
                editMode: false,
                usesRole: 'data-manager',
                sidebarHighlighter: 'manage-data'
            }
        })
        .state('no-permission', {
            parent: 'app',
            url: '/no-permission',
            views: {
                'parent': {
                    templateUrl: 'views/no-permission.html?nd=' + Date.now(),
                    controller: 'MainController as mainController'
                }
            }
        })
        .state('alerts', {
            parent: 'app',
            url: '/alerts',
            views: {
                'parent': {
                    templateUrl: 'views/modules/alerts.html?nd=' + Date.now(),
                    controller: 'MainController as main'
                }
            },

            params: {
                usesRole: 'no-role',
                sidebarHighlighter: 'alerts'
            }
        })

        .state('support-about', {
            parent: 'app',
            url: '/support-about',
            views: {
                'parent': {
                    templateUrl: 'views/front-end/support-about.html?nd=' + Date.now(),
                    controller: 'MainController as main'
                }
            },

            params: {
                usesRole: 'no-role',
                sidebarHighlighter: 'support-about'
            }
        })
        .state('job-setup', {
            parent: 'app',
            url: '/admin/job/{id:string}',
            views: {
                'parent': {
                    templateUrl: 'views/admin/job-setup.html?nd=' + Date.now(),
                    controller: 'MainController as main'
                }
            },
            params: {
                usesRole: 'job-setup'
            }
        })
        .state('edit-job-module', {
            parent: 'app',
            url: '/module/{id:string}/edit',
            views: {
                'parent': {
                    templateUrl: 'views/admin/job-setup.html?nd=' + Date.now(),
                    controller: 'MainController as main'
                }
            },
            params: {
                usesRole: 'job-setup'
            }
        })
        .state('module-setup', {
            parent: 'app',
            url: '/module/{uniqueModuleSlug:string}/edit',
            views: {
                'parent': {
                    templateUrl: 'views/admin/job-setup.html?nd=' + Date.now(),
                    controller: 'MainController as main'
                }
            },
            params: {
                usesRole: 'job-setup'
            }
        })
        .state('job', {
            parent: 'app',
            url: '/job/{id:string}',
            views: {
                'parent': {
                    templateUrl: 'views/job.html?nd=' + Date.now(),
                    controller: 'MainController as main'
                }
            },
            params: {
                usesRole: 'no-role'
            }
        })

        .state('digital-signature', {
            parent: 'app',
            url: '/digital-signature',
            views: {
                'parent': {
                    templateUrl: 'views/front-end/digital-signature.html?nd=' + Date.now(),
                    controller: 'MainController as main'
                }
            },
            params: {
                usesRole: 'no-role',
                sidebarHighlighter: 'home'
            }
        })
        .state('audits-complete', {
            parent: 'app',
            url: '/job/{id:string}/audits-complete',
            views: {
                'parent': {
                    templateUrl: 'views/admin/job-setup/audits-complete.html?nd=' + Date.now(),
                    controller: 'MainController as main'
                }
            },
            params: {
                usesRole: 'audits-complete'
            }
        })
        .state('module-edit-forms', {
            parent: 'app',
            url: '/module/{uniqueModuleSlug:string}/edit-forms',
            views: {
                'parent': {
                    templateUrl: 'views/admin/job-setup/create-scan-stations.html?nd=' + Date.now(),
                    controller: 'MainController as main'
                }
            },
            params: {
                usesRole: 'create-scan-stations',
                sidebarHighlighter: 'admin'
            }
        })

        .state('scan-station-edit', {
            parent: 'app',
            url: '/job/{id:string}/scan-station/{stationId:string}/edit',
            views: {
                'parent': {
                    templateUrl: 'views/admin/job-setup/scan-station-edit.html?nd=' + Date.now(),
                    controller: 'MainController as main'
                }, params: {
                    editEntity: true,
                    editMode: true
                }
            },
            params: {
                usesRole: 'scan-station-edit',
                sidebarHighlighter: 'admin'
            }
        })
        .state('module-edit-form', {
            parent: 'app',
            url: '/module/{uniqueModuleSlug:string}/edit-forms/{stationId:string}',
            views: {
                'parent': {
                    templateUrl: 'views/admin/job-setup/scan-station-edit.html?nd=' + Date.now(),
                    controller: 'MainController as main'
                }, params: {
                    editEntity: true,
                    editMode: true
                }
            },
            params: {
                usesRole: 'scan-station-edit',
                sidebarHighlighter: 'admin'
            }
        })
        .state('scan-station', {
            parent: 'app',
            url: '/job/{id:string}/scan-station/{stationId:string}',
            views: {
                'parent': {
                    templateUrl: 'views/front-end/scan-station.html?nd=' + Date.now(),
                    controller: 'MainController as main'
                }
            },
            params: {
                editEntity: true,
                editMode: true,
                sidebarHighlighter: ''
            }
        })
        .state('scan-station-with-data', {
            parent: 'app',
            url: '/job/{id:string}/scan-station/{stationId:string}/{jsonFields:string}/{masterScanStation:string}/{jsonDataToPass:string}',
            views: {
                'parent': {
                    templateUrl: 'views/front-end/scan-station.html?nd=' + Date.now(),
                    controller: 'MainController as main'
                }
            },
            params: {
                editEntity: true,
                editMode: true,
                sidebarHighlighter: ''
            }
        })
        .state('module-form', {
            parent: 'app',
            url: '/module/{uniqueModuleSlug:string}/form/{stationId:string}',
            views: {
                'parent': {
                    templateUrl: 'views/front-end/scan-station.html?nd=' + Date.now(),
                    controller: 'MainController as main'
                }
            },
            params: {
                editEntity: true,
                editMode: true,
                sidebarHighlighter: ''
            }
        })
        .state('scan-station-edit-entry', {
            parent: 'app',
            url: '/job/{id:string}/scan-station/{stationId:string}/edit-entry/{stationDataID:int}',
            views: {
                'parent': {
                    templateUrl: 'views/front-end/scan-station.html?nd=' + Date.now(),
                    controller: 'MainController as main'
                }
            },
            params: {
                editEntity: true,
                editMode: true,
                sidebarHighlighter: '',
                usesRole: 'can-edit-data-in-form'
            }
        })
        .state('sign-on-off', {
            parent: 'app',
            url: '/sign-on-off',
            views: {
                'parent': {
                    templateUrl: 'views/front-end/sign-on-off.html?nd=' + Date.now(),
                    controller: 'MainController as main'
                }, params: {
                    editEntity: true,
                    editMode: true
                }
            },
            params: {
                usesRole: 'sign-on-off',
                sidebarHighlighter: 'sign-on-off'
            }
        })
        .state('customer-active-jobs', {
            parent: 'app',
            url: '/customers-active-jobs',
            views: {
                'parent': {
                    templateUrl: 'views/modules/job-station-builder.html?nd=' + Date.now(),
                    controller: 'MainController as main'
                }, params: {
                    editEntity: true,
                    editMode: true
                }
            },
            params: {
                usesRole: 'customer-active-jobs',
                sidebarHighlighter: 'home'
            }
        })
        .state('customer-reports', {
            parent: 'app',
            url: '/customer-reports/{id:int}',
            views: {
                'parent': {
                    templateUrl: 'views/customer/reports.html?nd=' + Date.now(),
                    controller: 'MainController as main'
                }, params: {
                    editEntity: true,
                    editMode: true
                }
            },
            params: {
                usesRole: 'customer-reports',
            }
        })
        .state('view-image', {
            parent: 'app',
            url: '/view-image/{jobID:string}/{stationID:string}/{fileName:string}',
            views: {
                'parent': {
                    templateUrl: 'views/front-end/view-image.html?nd=' + Date.now(),
                    controller: 'MainController as main'
                }, params: {
                    editEntity: true,
                    editMode: true
                }
            },
            params: {
                usesRole: 'view-image',
            }
        })
        .state('job-station-builder', {
            parent: 'app',
            url: '/modules/job-station-builder',
            views: {
                'parent': {
                    templateUrl: 'views/modules/job-station-builder.html?nd=' + Date.now(),
                    controller: 'MainController as main'
                }, params: {
                    editEntity: true,
                    editMode: true
                }
            },
            params: {
                usesRole: 'job-station-builder',
                sidebarHighlighter: 'home'
            }
        })
        .state('manage-modules', {
            parent: 'app',
            url: '/admin/manage-modules',
            views: {
                'parent': {
                    templateUrl: 'views/admin/manage-modules.html?nd=' + Date.now(),
                    controller: 'MainController as main'
                }, params: {
                    editEntity: true,
                    editMode: true
                }
            },
            params: {
                usesRole: 'admin',
                sidebarHighlighter: 'home'
            }
        })
        .state('schedule-database-export', {
            parent: 'app',
            url: '/admin/schedule-database-export',
            views: {
                'parent': {
                    templateUrl: 'components/export/template/schedule-database-export.html?nd=' + Date.now(),
                    controller: 'MainController as main'
                    // controller: 'ExportController as exp'
                }
            },
            params: {
                usesRole: 'admin-landing-page',
                sidebarHighlighter: 'admin'
            }
        })
        .state('manage-customers', {
            parent: 'app',
            url: '/admin/manage-customers',
            views: {
                'parent': {
                    templateUrl: 'views/admin/manage-customers.html?nd=' + Date.now(),
                    controller: 'MainController as main'
                }
            },
            params: {
                sidebarHighlighter: 'admin'
            }
        })
        .state('manage-customer', {
            parent: 'app',
            url: '/admin/manage-customers/{customerID:int}',
            views: {
                'parent': {
                    templateUrl: 'views/admin/manage-customer.html?nd=' + Date.now(),
                    controller: 'MainController as main'
                }
            },
            params: {
                sidebarHighlighter: 'admin'
            }
        })
        .state('general-audit', {
            parent: 'app',
            url: '/admin/general-audit',
            views: {
                'parent': {
                    templateUrl: 'views/admin/general-audit.html?nd=' + Date.now(),
                    controller: 'MainController as main'
                }
            },
            params: {
                sidebarHighlighter: 'admin'
            }
        })
        .state('sop-sign-offs', {
            parent: 'app',
            url: '/admin/sop-sign-offs',
            views: {
                'parent': {
                    templateUrl: 'views/admin/sop-sign-offs.html?nd=' + Date.now(),
                    controller: 'MainController as main'
                }
            },
            params: {
                sidebarHighlighter: 'admin'
            }
        })
        .state('raw-data-and-count-reports', {
            parent: 'app',
            url: '/manage-data/raw-data-and-count-reports',
            views: {
                'parent': {
                    templateUrl: 'views/modules/job-station-builder.html?nd=' + Date.now(),
                    controller: 'MainController as main'
                }
            },
            params: {
                sidebarHighlighter: 'manage-data'
            }
        })
        .state('training-records', {
            parent: 'app',
            url: '/training-records',
            views: {
                'parent': {
                    templateUrl: 'views/job.html?nd=' + Date.now(),
                    controller: 'MainController as main'
                }
            },
            params: {
                sidebarHighlighter: 'training-records'
            }
        })
        .state('job-module', {
            parent: 'app',
            url: '/module/{uniqueModuleSlug:string}',
            views: {
                'parent': {
                    templateUrl: 'views/job.html?nd=' + Date.now(),
                    controller: 'MainController as main'
                }
            },
            params: {
                sidebarHighlighter: 'home'
            }
        })
        .state('hr', {
            parent: 'app',
            url: '/hr',
            views: {
                'parent': {
                    templateUrl: 'views/job.html?nd=' + Date.now(),
                    controller: 'MainController as main'
                }
            },
            params: {
                sidebarHighlighter: 'hr'
            }
        })
        .state('crm', {
            parent: 'app',
            url: '/crm',
            views: {
                'parent': {
                    templateUrl: 'views/job.html?nd=' + Date.now(),
                    controller: 'MainController as main'
                }
            },
            params: {
                sidebarHighlighter: 'crm'
            }
        })
        .state('contracts', {
            parent: 'app',
            url: '/contracts',
            views: {
                'parent': {
                    templateUrl: 'views/job.html?nd=' + Date.now(),
                    controller: 'MainController as main'
                }
            },
            params: {
                sidebarHighlighter: 'contracts'
            }
        })
        .state('sign-documents', {
            parent: 'app',
            url: '/sign-documents',
            views: {
                'parent': {
                    templateUrl: 'views/job.html?nd=' + Date.now(),
                    controller: 'MainController as main'
                }
            },
            params: {
                sidebarHighlighter: 'sign-documents'
            }
        })
        .state('timesheets', {
            parent: 'app',
            url: '/timesheets',
            views: {
                'parent': {
                    templateUrl: 'views/job.html?nd=' + Date.now(),
                    controller: 'MainController as main'
                }
            },
            params: {
                sidebarHighlighter: 'training-records'
            }
        })
        .state('training-records-edit', {
            parent: 'app',
            url: '/training-records/edit',
            views: {
                'parent': {
                    templateUrl: 'views/admin/job-setup/create-scan-stations.html?nd=' + Date.now(),
                    controller: 'MainController as main'
                }
            },
            params: {
                sidebarHighlighter: 'training-records'
            }
        })
        .state('training-records-module-edit', {
            parent: 'app',
            url: '/training-records/edit',
            views: {
                'parent': {
                    templateUrl: 'views/admin/job-setup/create-scan-stations.html?nd=' + Date.now(),
                    controller: 'MainController as main'
                }
            },
            params: {
                sidebarHighlighter: 'training-records'
            }
        })
        .state('timesheets-edit', {
            parent: 'app',
            url: '/timesheets/edit',
            views: {
                'parent': {
                    templateUrl: 'views/admin/job-setup/create-scan-stations.html?nd=' + Date.now(),
                    controller: 'MainController as main'
                }
            },
            params: {
                sidebarHighlighter: 'timesheets'
            }
        })
        .state('job-reports', {
            parent: 'app',
            url: '/job-reports/{id:string}',
            views: {
                'parent': {
                    templateUrl: 'views/job-reports.html?nd=' + Date.now(),
                    controller: 'MainController as main'
                }
            },
            params: {
                sidebarHighlighter: 'manage-data',
                usesRole: 'raw-data-and-count-reports'
            }
        })
        .state('manage-data', {
            parent: 'app',
            url: '/manage-data',
            views: {
                'parent': {
                    templateUrl: 'views/manage-data/manage-data.html?nd=' + Date.now(),
                    controller: 'MainController as main'
                }
            },
            params: {
                sidebarHighlighter: 'manage-data',
                usesRole: 'manage-data'
            }
        })
        .state('generate-barcodes', {
            parent: 'app',
            url: '/generate-barcodes',
            views: {
                'parent': {
                    templateUrl: 'views/generate-barcodes.html?nd=' + Date.now(),
                    controller: 'MainController as main'
                }
            },
            params: {
                sidebarHighlighter: 'home'
            }
        })
        .state('import-data-file', {
            parent: 'app',
            url: '/job/{id:string}/import-data-file',
            views: {
                'parent': {
                    templateUrl: 'views/admin/upload-data-file.html?nd=' + Date.now(),
                    controller: 'MainController as main'
                }
            },
            params: {
                sidebarHighlighter: 'manage-data'
            }
        }).state('manage-pages', {
        parent: 'app',
        url: '/admin/manage-pages',
        views: {
            'parent': {
                templateUrl: 'components/page/template/manage-pages.html?nd=' + Date.now(),
                controller: 'MainController as main'
            }
        }
    })
        .state('absolute-url', {
            parent: 'app',
            views: {
                'parent': {
                    url: 'url-redirect?urlgo={url:string}',
                    templateUrl: 'components/page/template/edit-page.html?nd=' + Date.now(),
                    controller: 'MainController as main'
                }
            }
        })
        .state('edit-url', {
            parent: 'app',
            url: '/url/{urlId:string}/edit',
            views: {
                'parent': {
                    templateUrl: 'components/url/template/edit-url.html?nd=' + Date.now(),
                    controller: 'MainController as main'
                }
            }
        })
        .state('edit-page', {
            parent: 'app',
            url: '/page/{pageId:string}/edit',
            views: {
                'parent': {
                    templateUrl: 'components/page/template/edit-page.html?nd=' + Date.now(),
                    controller: 'MainController as main'
                }
            }
        })
        .state('page', {
            parent: 'app',
            url: '/page/{pageSlug:string}',
            views: {
                'parent': {
                    templateUrl: 'components/page/template/page.html?nd=' + Date.now(),
                    controller: 'MainController as main'
                }
            }
        }).state('edit-bloc', {
        parent: 'app',
        url: '/module/{id:string}/bloc/{stationId:string}/edit',
        views: {
            'parent': {
                templateUrl: 'views/admin/job-setup/scan-station-edit.html?nd=' + Date.now(),
                controller: 'MainController as main'
            }, params: {
                editEntity: true,
                editMode: true
            }
        },
        params: {
            usesRole: 'scan-station-edit',
            sidebarHighlighter: 'admin'
        }
    }).state('bloc', {
        parent: 'app',
        url: '/module/{uniqueModuleSlug:string}/bloc/{stationId:string}?urlData&urlFieldSlugData&showMoreViewing&redirectToUniqueModuleSlug&redirectToScanStationId&fieldSlugsFilterOnly',
        views: {
            'parent': {
                templateUrl: 'views/front-end/scan-station.html?nd=' + Date.now(),
                controller: 'MainController as main'
            }
        },
        params: {
            editEntity: true,
            editMode: true,
            sidebarHighlighter: ''
        }
    }).state('html-bloc', {
        parent: 'app',
        url: '/module/{uniqueModuleSlug:string}/html-bloc/{stationId:string}?urlData&urlFieldSlugData&showMoreViewing&redirectToUniqueModuleSlug&redirectToScanStationId&fieldSlugsFilterOnly&token',
        views: {
            'parent': {
                templateUrl: 'views/front-end/html-bloc.html?nd=' + Date.now(),
                controller: 'MainController as main'
            }
        },
        params: {
            editEntity: true,
            editMode: true,
            sidebarHighlighter: ''
        }
    }).state('roles-and-permissions', {
        parent: 'app',
        url: '/admin/roles-and-permissions',
        views: {
            'parent': {
                templateUrl: 'components/roles-and-permissions/template/roles-and-permissions-loader.html?nd=' + Date.now(),
                controller: 'MainController as main'
            }
        },
        params: {
            usesRole: 'manage-user-permissions',
            editEntity: true,
            editMode: true,
            sidebarHighlighter: ''
        }
    }).state('app-settings', {
        parent: 'app',
        url: '/admin/app-settings',
        views: {
            'parent': {
                templateUrl: 'components/app-settings-loader/template/app-settings-loader.html?nd=' + Date.now(),
                controller: 'MainController as main'
            }
        },
        params: {
            usesRole: 'manage-user-permissions',
            editEntity: true,
            editMode: true,
            sidebarHighlighter: ''
        }
    }).state('schedulers-data', {
        parent: 'app',
        url: '/manage-data/schedulers-data',
        views: {
            'parent': {
                templateUrl: 'components/schedulers-data-loader/template/schedulers-data-loader.html?nd=' + Date.now(),
                controller: 'MainController as main'
            }
        },
        params: {
            sidebarHighlighter: 'manage-data'
        }
    });

}
