import * as angular from 'angular';

"use strict";

angular.module('ScanStationModule')
    .service('FlowChartService', FlowChartService);

/**
 * Service constructor.
 * @constructor
 */
function FlowChartService() {
    let service = this;

}