import * as angular from 'angular';

'use strict';

angular.module('LanguagesModule')
    .service('Languages', Languages);


Languages.$inject = ['Data', '$http', 'Configuration'];

/**
 * This will be responsible to implement languages on the
 * application.
 *
 * @constructor
 * @deprecated
 */
function Languages(Data, $http, Configuration) {

    const service = this;

    service.$get = function () {
    }

    service.getWords = async function (isLoggedIn) {

        let languageSetting = 'en_EN';
        // the appSetting will not be loaded if logged out and first time loading page
        // if logged in and language changes they will need to log out and back in again
        if (isLoggedIn != true) {
            let defaultLanguage = await Data.getDefaultLanguage();
            window.localStorage.setItem('appSettings', JSON.stringify(defaultLanguage));
            let appSettingParsed = JSON.parse(localStorage.getItem('appSettings'));

            if(appSettingParsed.setting_key != null && appSettingParsed.setting_key == 'language') {
                languageSetting = appSettingParsed.setting_value;
            }
        } else {

            let appSettingParsed = JSON.parse(localStorage.getItem('appSettings'));

            let appSettingList = appSettingParsed;

            if(appSettingParsed.data != null) {
                appSettingList = appSettingParsed.data;
            }

            angular.forEach(appSettingList, function(settingEntry) {
                if(settingEntry.setting_key == 'language') {
                    languageSetting = settingEntry.setting_value;

                }
            });

        }

        let randomValue = Configuration.getRandomValue();
        
        let data = await $http.get('/language-files/' + languageSetting + '.json?rand=' + randomValue, {});

        if (typeof data.data == "string"){
            data.data = {}
        }

        if(isLoggedIn == true) {
            window.localStorage.setItem('translatedWords', JSON.stringify(data.data));
        }

        return data ;

    }

    return service;

}
