import * as angular from 'angular';

"use strict";

angular.module('IconModule')
    .controller('IconController', IconController);

// Injection of each class
IconController.$inject = ['IconFactory', 'Configuration', '$scope', 'scanStation'];

// Controller Constructor
function IconController(IconFactory, Configuration, $scope, scanStation) {
    // Creating the view model reference
    let vm = this;
    vm.data = '';
    vm.main = '';
    vm.page = '';
    vm.scanStation = '';

    vm.$onInit = async function () {
        try {
            let version = Configuration.getVersion();
            let random = Configuration.getRandomValue();

            if (vm.data !== "") {
                $scope.data = vm.data;
            }

            if (vm.scanStation !== "") {
                $scope.scanStation = vm.scanStation;
            }

            if (vm.main !== "") {
                $scope.main = vm.main;
            }

            if (vm.page !== "") {
                $scope.page = vm.page;
            }

            vm.iconSelectPopUp = 'components/icon/template/select-icon.html?v=' + version + 'rand=' + random;

        } catch (e) {
            console.log(e);
        }

    }

    /**
     * This will check if exist anything on data or page
     * if can find it will be getting the value from it
     * or will return false
     */
    vm.showPopup = function () {
        try {
            let result = false;
            let element = false;

            // this will check if exist data into page, if not will be a data value
            const showIconBox = vm.page !== undefined ? vm.page.showIconBox : vm.data.showIconBox;

            // check if we have value in the showIconBox variable
            if (showIconBox !== undefined) {
                result = showIconBox;
            }

            return result;

        } catch (e) {
            console.log(e);
        }

    }

    /**
     * This will be responsible to close the popup.
     */
    vm.hidePopup = function () {
        try {
            // getting the correct object to change the data
            if (vm.page !== undefined) {
                vm.page.showIconBox = false;
            } else {
                vm.data.showIconBox = false;
            }

        } catch (e) {
            console.log(e);
        }

    }

    /**
     * This will be responsible to update an Icon.
     *
     */
    vm.updateIcon = async function (scanStationId, newIcon) {
        try {
            let response = await IconFactory.updateIcon(scanStationId, newIcon);
            await vm.scanStation.triggerBlocUpdateSuccess('Icon Updated');
            $scope.$apply();

        } catch (e) {
            console.log(e);
            if(e.data.message) {
                alert(e.data.message);
            }
        }
    }

    /**
     * This will be responsible for load the actual icon of
     * a scan station.
     *
     * @param scanStationId
     */
    vm.loadIcon = async function (scanStationId) {
        try {

            let icon = await scanStation.loadIcon(scanStationId);
            vm.data.newScanStationIcon = icon;

        } catch (e) {
            console.log(e);
        }
    }

    /**
     * Open the select icon popup
     *
     */
    vm.openIconsPopup = function () {
        try {
            // getting the correct object to change the data
            if (vm.page !== "" && vm.page !== undefined) {
                vm.page.showIconBox = true;
                vm.page.editingBlocIcon = true;
                vm.page.editingModuleIcon = false;
            } else {
                vm.data.showIconBox = true;
                vm.data.editingBlocIcon = true;
                vm.data.editingModuleIcon = false;
            }

        } catch (e) {
            console.log(e);
        }
    }

    /**
     * This will validate the current form.
     *
     */
    vm.formValidate = function () {
        try {
            return true;

        } catch (e) {
            console.log(e);
        }
    }

    vm.listIconFileNames = async function () {
        try {
            let response = await IconFactory.listIconFileNames();
            vm.iconFileNames = response.data.data;
            $scope.$apply();

        } catch (e) {
            console.log(e);
            if(e.data.message){
                alert(e.data.message);
            }
        }

    }

}

export default IconController;