import * as angular from 'angular';
import {file} from "babel-types";

"use strict";

angular.module('FieldModule')
       .directive('updateData', updateData);


/**
 * Directive object that will make possible ngModel and ngChange
 * can be existent for divs.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function updateData($parse) {
    let directive = this;
    return {
        restrict: 'E',
        scope: {
            val: '=',
            onDataRefresh: '&'
        },
        link: function(scope, element, attrs) {

            scope.$watch('val', async function(newValue, oldValue) {
                if (newValue){
                    await scope.onDataRefresh();
                }
            }, true);
        }
    };
}
