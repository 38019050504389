import * as angular from 'angular';
import StandardComponent from "components/standards/components/StandardComponent";
import FlowChartController from "./controllers/flow-chart.controller";

"use strict";

class FlowChartComponent extends StandardComponent {

    // This will enable the debug mode of this class
    public debug = false;
    public moduleFolder = 'components/flow-chart';

    /**
     * PLease make sure that each function contain correct
     * documentation.
     */
    public bindings = {
        action: '@',
        scanStation: '=',
    }

    // This will set what will be the controller of this component
    public controller : any = FlowChartController;

    // This will set an alias
    public controllerAs: string = 'flowChart';
}

angular.module('ScanStationModule').component('flowChart', <StandardComponent> new FlowChartComponent);