import * as angular from 'angular';

'use strict';

angular.module('ModuleModule')
    .service('ModuleService', ModuleService)
    .config(ModuleConfiguration);

/**
 * Object that will set aHrefSanitizationWhitelist for requests
 * in this factory.
 *
 * @param $compileProvider
 * @constructor
 */
function ModuleConfiguration($compileProvider: any) {
    $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|tel|file|blob):/);
}

ModuleService.$inject = ['$http', 'Configuration'];

/**
 *
 * This service provides functionality for exporting a module.
 * @param $http - The AngularJS $http service for making HTTP requests with job ID.
 *
 */
function ModuleService($http, Configuration): void {
    let service = this;

    let apiUrl = Configuration.getApiUrl();

    /**
     * This function will export a module.
     * @param jobID
     */
    service.exportModule = (jobID) => {
        const apiBase = apiUrl + 'export-module';
        const config = {
            responseType: 'blob',
            params: {
                jobID
            }
        }
        return $http.get(apiBase, config)
    }

    return service;
}
