import * as angular from 'angular';

"use strict";

angular.module('FieldModule')
       .directive('checkboxDynamicCheckedValueField', checkboxDynamicCheckedValueFieldDirective)
       .directive('checkboxDynamicCheckedValueFieldPrint', checkboxDynamicCheckedValueFieldPrintDirective)
       .service('checkboxDynamicCheckedValueField', checkboxDynamicCheckedValueField);

checkboxDynamicCheckedValueFieldDirective.$inject = ['Configuration'];

/**
 * Directive object that will build the field.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function checkboxDynamicCheckedValueFieldDirective(Configuration) {
    let directive = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/checkbox-dynamic-checked-value/field.html?v=' + version + 'rand=' + random;

    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl
    });
}

checkboxDynamicCheckedValueFieldPrintDirective.$inject = ['Configuration'];

/**
 * Directive that will show the field result in the table.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function checkboxDynamicCheckedValueFieldPrintDirective(Configuration) {
    let directive = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/checkbox-dynamic-checked-value/print.html?v=' + version + 'rand=' + random;

    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl
    });
}

/**
 * Service constructor.
 * @constructor
 */
function checkboxDynamicCheckedValueField() {
    let service = this;

    /**
     * Returns the initial Parameters for this field, to be distinguished from other parameters the field may have in the future after creating it
     */
    service.getInitialParams = async function () {
        try
        {
            let initialParams = {
                'pressedUnpressedValues': {
                    'subParameters': {
                        'pressed': {
                            'validation' : {
                                'required': false,
                                'validationResponse': 'Please provide a value for when the checkbox is ticked',
                                'requiredType': 'stringMustNotBeEmpty'
                            }
                        },
                        'unPressed': {
                            'validation' : {
                                'required': false,
                                'validationResponse': 'Please provide a value for when the checkbox is unticked',
                                'requiredType': 'stringMustNotBeEmpty'
                            }
                        }

                    },
                    'validation' : {
                        'required': false,
                        'validationResponse': 'Please provide values for the checked and unchecked options',
                        'requiredType': 'listMustHaveOne'
                    }
                },
                'populateCheckedValueElseWhereFieldID': {
                    'validation' : {
                        'required': true,
                        'validationResponse': 'Please provide values for the checked and unchecked options',
                        'requiredType': 'stringMustNotBeEmpty'
                    }
                }
            }

            return initialParams;

        } catch (e) {
            console.log(e);
        }
    }

}
