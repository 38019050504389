import * as angular from 'angular';

"use strict";

angular.module('BlocworxModule')
       .controller('GridFieldController', GridFieldController);

GridFieldController.$inject = ['$scope','ScopeAndLocal'];

// Controller Constructor
function GridFieldController($scope, ScopeAndLocal) {

    // Creating the view model reference
    let vm = this;

    /**
     * Constructor class.
     */
    vm.$onInit = async function () {
        try
        {
            ScopeAndLocal.startVariable(vm, $scope, 'main');
            $scope.main = vm.main;
            ScopeAndLocal.startVariable(vm, $scope, 'data');
            $scope.data = vm.data;
            ScopeAndLocal.startVariable(vm, $scope, 'grid');
            $scope.grid = vm.grid;
            ScopeAndLocal.startVariable(vm, $scope, 'child');
            $scope.child = vm.child;
            ScopeAndLocal.startVariable(vm, $scope, 'key');
            $scope.key = vm.key;
            ScopeAndLocal.startVariable(vm, $scope, 'gridfield');
            $scope.gridField = vm.gridField;
            ScopeAndLocal.startVariable(vm, $scope, 'scanStationController');
            $scope.scanStationController = vm.scanStationController;
            ScopeAndLocal.startVariable(vm, $scope, 'scanStation');
            $scope.scanStation = vm.scanStation;
            ScopeAndLocal.startVariable(vm, $scope, 'fc');
            $scope.fc = vm.fc;

            if(vm.gridfield.field_id != undefined){
                vm.fieldId = vm.gridfield.field_id;
            }

        } catch (e) {
            console.log(e);
        }
    }

    /**
     * This function will make sure that loads the field from the scan station fieldsIDsToField
     * or if it is a new one must do a request to the scanStationService load field by passing
     * its id.
     *
     * @param field_id
     */
    vm.loadField = async (field_id) => {

        let field = false;

        if(field_id == undefined){
            return false
        }

        // checking the data on scan station controller
        let loadingFieldByScanStationController = vm.scanStationController?.scanStationObj.scanStationFieldIdsToField[field_id];

        // loading the field data
        field = (loadingFieldByScanStationController != undefined)
            ? vm.scanStationController.scanStationObj.scanStationFieldIdsToField[field_id]
            : await vm.scanStationController.scanStationService.loadField(field_id);

        vm.field = $scope.field = field;

    }

    vm.getField = async () => {
        if(!vm.field){
            $scope.field = $scope.field = await vm.scanStationController.scanStationService.loadField(vm.fieldId);
            $scope.$apply();
        }
    }

}

export default GridFieldController;