import * as angular from 'angular';
import {file} from "babel-types";
import {DataFiltering} from '@/legacy/controllers/DataFiltering.interface'
import Language from 'components/languages/controllers/language'
import {TextFieldServiceBLCXAU} from "angular-blocworx/components/fields/text/text.service";
import {ResultsBoxService} from "angular-blocworx/components/fields/results-box/results-box.service";


"use strict";

angular.module('FieldModule')
    .service('fieldService', fieldService)
    .directive('getMoulesByKeyWord', getMoulesByKeyWord)
    .directive('getMoulesByKeyWordTwo', getMoulesByKeyWordTwo);

fieldService.$inject = [
    'TextFieldServiceBLCXAU',
    'multiButtonField',
    'checkboxField',
    'checkboxDynamicCheckedValueField',
    'alertButtonField',
    'autocompleteField',
    'autoGenerateField',
    'bartenderFileGeneratorField',
    'buttonField',
    'dateTimeSelectorField',
    'digitalSignatureField',
    'dropdownFromListField',
    'dropdownFromStationField',
    'fieldMergeBuilderField',
    'fileField',
    'formception2Field',
    'formListField',
    'htmlField',
    'ImageUrlFieldService',
    'inputNumericField',
    'inputTextareaField',
    'jobsListDropdownField',
    'labelField',
    'loggedInUserInfoField',
    'mathsField',
    'multiFilesField',
    'PieChartField',
    'plainDataField',
    'radioButtonsFromStationField',
    'linkField',
    'checkboxFromStationField',
    'ResultsBoxService',
    'sectionSelectField',
    'separatorField',
    'timeDifferenceField',
    'TotalResultsField',
    'userSelectField',
    'wysiwygField',
    'wysiwygForUserFieldField',
    'EChartField',
    'crossBlocDataUpdatesField',
    'qrcodeField',
    'mindeeField',
    'createUserPopUpField',
    'submitButtonField',
    'mailAlertField',
    'fileBuilderField',
    'barcodeGeneratorField',
    'blocworxGatewayField',
    'bluetoothField',
    '$http',
    'Configuration',
    'CalendarResourcePlanningService'
]

/**
 * Service constructor.
 * @constructor
 */
function fieldService(TextFieldServiceBLCXAU,
                      multiButtonField,
                      checkboxField,
                      checkboxDynamicCheckedValueField,
                      alertButtonField,
                      autocompleteField,
                      autoGenerateField,
                      bartenderFileGeneratorField,
                      buttonField,
                      dateTimeSelectorField,
                      digitalSignatureField,
                      dropdownFromListField,
                      dropdownFromStationField,
                      fieldMergeBuilderField,
                      fileField,
                      formception2Field,
                      formListField,
                      htmlField,
                      ImageUrlFieldService,
                      inputNumericField,
                      inputTextareaField,
                      jobsListDropdownField,
                      labelField,
                      loggedInUserInfoField,
                      mathsField,
                      multiFilesField,
                      PieChartField,
                      plainDataField,
                      radioButtonsFromStationField,
                      linkField,
                      checkboxFromStationField,
                      ResultsBoxService,
                      sectionSelectField,
                      separatorField,
                      timeDifferenceField,
                      TotalResultsField,
                      userSelectField,
                      wysiwygField,
                      wysiwygForUserFieldField,
                      EChartField,
                      crossBlocDataUpdatesField,
                      qrcodeField,
                      mindeeField,
                      createUserPopUpField,
                      submitButtonField,
                      mailAlertField,
                      fileBuilderField,
                      barcodeGeneratorField,
                      blocworxGatewayField,
                      bluetoothField,
                      $http,
                      Configuration,
                      CalendarResourcePlanningService) {
    let service = this;

    service.apiUrl = Configuration.getApiUrl();

    /**
     * Method that will get a field by the field id,
     *
     * @param fieldId id of the field.
     * $param scanStationFields to look through
     * @returns {boolean} false if can't find, otherwise will
     * return the field object.
     */
    service.getFieldByFieldId = function (fieldId, scanStationFields) {
        let filterField = false;

        // getting information of the field
        angular.forEach(scanStationFields, function (field) {
            if (field.id == fieldId) {
                filterField = field;
            }
        });

        return filterField;
    }

    /**
     * This will be getting all fields parameters.
     *
     * @param fieldId
     */
    service.getAllFieldParameters = async (fieldId) => {

        let fieldParameters;
        let apiBase = service.apiUrl + 'get-field-parameters';
        let config = {
            handleError: true,
            params: {
                fieldId: fieldId
            }
        };

        let responseData = await $http.get(apiBase, config);
        if (responseData?.data?.data != null) {
            fieldParameters = responseData.data.data;
        }

        return fieldParameters;
    }

    /**
     * This we decide what is the service that will load our parameters when we click
     * to edit the field.
     *
     * @param fieldType
     */
    service.getInitialParams = async function (fieldType) {

        let initialParams = {};

        switch (fieldType) {
            case 'text':
                initialParams = await TextFieldServiceBLCXAU.getInitialParams();
                break;
            case 'multi-button':
                initialParams = await multiButtonField.getInitialParams();
                break;
            case 'checkbox':
                initialParams = await checkboxField.getInitialParams();
                break;
            case 'checkbox-dynamic-checked-value':
                initialParams = await checkboxDynamicCheckedValueField.getInitialParams();
                break;
            case 'alert-button':
                initialParams = await alertButtonField.getInitialParams();
                break;
            case 'auto-generate-time':
            case 'auto-generate-full-timestamp':
            case 'auto-generate-date':
            case 'auto-generate-number':
            case 'auto-generate-number-text':
            case 'auto-generate-number-date':
            case 'auto-generate-number-text-date':
            case 'auto-generate-custom':
                initialParams = await autoGenerateField.getInitialParams(fieldType);
                break;
            case 'autocomplete':
                initialParams = await autocompleteField.getInitialParams();
                break;
            case 'bartender-file-generator':
                initialParams = await bartenderFileGeneratorField.getInitialParams();
                break;
            case 'button':
                initialParams = await buttonField.getInitialParams();
                break;
            case 'data-to-form':
                initialParams = await formListField.getInitialParams();
                break;
            case 'date-time-selector':
                initialParams = await dateTimeSelectorField.getInitialParams();
                break;
            case 'digital-signature':
                initialParams = await digitalSignatureField.getInitialParams();
                break;
            case 'dropdown-from-station':
                initialParams = await dropdownFromStationField.getInitialParams();
                break;
            case 'field-merge-builder':
                initialParams = await fieldMergeBuilderField.getInitialParams();
                break;
            case 'file':
                initialParams = await fileField.getInitialParams();
                break;
            case 'formception-2':
                initialParams = await formception2Field.getInitialParams();
                break;
            case 'html':
                initialParams = await htmlField.getInitialParams();
                break;
            case 'image-url-field':
                initialParams = await ImageUrlFieldService.getInitialParams();
                break;
            case 'textarea':
                initialParams = await inputTextareaField.getInitialParams();
                break;
            case 'jobs-list-dropdown':
                initialParams = await jobsListDropdownField.getInitialParams();
                break;
            case 'label':
                initialParams = await labelField.getInitialParams();
                break;
            case 'logged-in-user-info':
                initialParams = await loggedInUserInfoField.getInitialParams();
                break;
            case 'maths':
                initialParams = await mathsField.getInitialParams();
                break;
            case 'multi-file':
                initialParams = await multiFilesField.getInitialParams();
                break;
            case 'pie-chart':
                initialParams = await PieChartField.getInitialParams();
                break;
            case 'plain-data':
                initialParams = await plainDataField.getInitialParams();
                break;
            case 'radio-buttons-from-station':
                initialParams = await radioButtonsFromStationField.getInitialParams();
                break;

            case 'link-data-field':
                initialParams = await linkField.getInitialParams();
                break;

            case 'checkbox-buttons-from-station':
                initialParams = await checkboxFromStationField.getInitialParams();
                break;
            case 'results-box':
                initialParams = await ResultsBoxService.getInitialParams();
                break;
            case 'section-select':
                initialParams = await sectionSelectField.getInitialParams();
                break;
            case 'time-diff':
                initialParams = await timeDifferenceField.getInitialParams();
                break;
            case 'total-results':
                initialParams = await TotalResultsField.getInitialParams();
                break;
            case 'user-select':
                initialParams = await userSelectField.getInitialParams();
                break;
            case 'wysiwyg':
                initialParams = await wysiwygField.getInitialParams();
                break;
            case 'wysiwyg-for-user':
                initialParams = await wysiwygForUserFieldField.getInitialParams();
                break;
            case 'echart':
                initialParams = await EChartField.getInitialParams();
                break;
            case 'cross-bloc-data-updates':
                initialParams = await crossBlocDataUpdatesField.getInitialParams();
                break;

            case 'qrcode':
                initialParams = await qrcodeField.getInitialParams();
                break;

            case 'mindee':
                initialParams = await mindeeField.getInitialParams();
                break;

            case 'create-user-popup':
                initialParams = await createUserPopUpField.getInitialParams();
                break;

            case 'submit-button-field' :
                initialParams = await submitButtonField.getInitialParams();
                break;

            case 'dropdown-from-list' :
                initialParams = await dropdownFromListField.getInitialParams();
                break;

            case 'mail-alert' :
                initialParams = await mailAlertField.getInitialParams();
                break;

            case 'file-builder' :
                initialParams = await fileBuilderField.getInitialParams();
                break;

            case 'barcode-generator' :
                initialParams = await barcodeGeneratorField.getInitialParams();
                break;

            case 'blocworx-gateway' :
                initialParams = await blocworxGatewayField.getInitialParams();
                break;

            case 'bluetooth' :
                initialParams = await bluetoothField.getInitialParams();
                break;

            case 'calendar-resource-planning':
                initialParams = await CalendarResourcePlanningService.getInitialParams();
                break;

            default:
                console.log('New Field Type: ' + fieldType)
        }

        return initialParams;
    }


    /**
     * This will be a generic reusable function responsible to build the list of fields
     *
     * @param onlyShowFieldArray
     * @param field
     */
    service.addSingleFieldList = (fieldArray, fieldId = null) => {

        if (fieldId == null) {

            alert('Please Select a Field');
            return fieldArray;
        }


        if (typeof fieldArray == 'undefined' || (typeof fieldArray != 'undefined' && fieldArray.length == 0) || (typeof fieldArray === 'object' && Object.keys(fieldArray).length == 0)) {
            fieldArray = [];
        }

        // rebuild to an array if its an object
        if (typeof fieldArray === 'object' && Object.keys(fieldArray).length > 0) {
            let temporaryArray = [];

            angular.forEach(fieldArray, function (value) {
                temporaryArray.push(value);
            })

            fieldArray = temporaryArray;
        }

        fieldArray.push(fieldId);

        return fieldArray;
    }

    service.removeFromFieldList = (fieldArray, index = null) => {

        if (index == null) {
            alert('Please Select a field');
            return fieldArray;
        }

        delete fieldArray[index];

        // build new object with fresh keys to keep it in sync
        let fieldListForLoop = fieldArray;
        fieldArray = [];
        let i = 0;

        angular.forEach(fieldListForLoop, function (value) {
            fieldArray[i] = value;
            i++;
        });
        return fieldArray;
    }

}

/**
 * Directive object that will build or edit the field.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function getMoulesByKeyWord(Configuration) {
    let directive = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/common/get-modules-by-keyword.html?v=' + version + 'rand=' + random;
    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl,
        link: function (scope, element, attrs) {
            if (attrs.parametersetting) {
                scope.parameterSetting = scope.$eval(attrs.parametersetting);
            }
        }
    });
}

/**
 * Directive object that will build or edit the field.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */

// TODO create a component to avoid having to use almost identicial directives. The below was done to allow for a second different module search
function getMoulesByKeyWordTwo(Configuration) {
    let directive = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/common/get-modules-by-keyword-two.html?v=' + version + 'rand=' + random;
    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl,
        link: function (scope, element, attrs) {
            if (attrs.parametersetting) {
                scope.parameterSetting = scope.$eval(attrs.parametersetting);
            }
        }
    });
}



